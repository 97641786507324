<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col lg:flex-row justify-between">
      <UITabs v-model="tab" :tabs="tabs" />
    </div>

    <router-view v-slot="{ Component }">
      <KeepAlive>
        <component :is="Component" v-bind="$attrs" />
      </KeepAlive>
    </router-view>
  </div>
</template>

<script setup>
import { onActivated, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import UITabs from '@/components/UI/Tabs.vue'

const router = useRouter()
const route = useRoute()

const tabs = ['Overzicht', 'Maand', 'Jaar']
const tab = ref(Math.max(0, tabs.findIndex(t => route.name.includes(`StatsDashboardRitten${t}`))))

watch(tab, () => {
  router.push({ name: `StatsDashboardRitten${tabs[tab.value]}` })
})

const handleActivate = () => {
  tab.value = 0
}
onActivated(handleActivate)
onMounted(handleActivate)
</script>
