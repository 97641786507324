<template>
  <div :key="renderKey" class="flex flex-col gap-8">
    <StatsCalculatedAt :calculated-at="calculatedAt" />

    <UILoading v-if="loading || loadingVergelijk" />
    <template v-else>
      <div class="card">
        <h2>{{ yearly ? 'Jaar' : 'Maand' }} overzicht - Dag waarde</h2>
        <div>
          <BarStatsAPR
            :waarden="Object.values(grafiekData).map(el => el.value || 0)"
            :waarden-vergelijk="Object.values(grafiekDataVergelijk).map(el => el.value || 0)"
            :waarden-bam="timeframe === 'MONTH' ? bams.omzet.daily : bams.omzet.monthly"
            :waarden-target="timeframe === 'MONTH' ? targets.omzet.daily : targets.omzet.monthly"
            waarden-label="Dag waarde"
          />
        </div>
      </div>

      <div class="card">
        <h2>{{ yearly ? 'Jaar' : 'Maand' }} overzicht - Aantal enkele ritten</h2>
        <div>
          <BarStatsAPR
            :ritten="Object.values(grafiekData).map(el => el.count || 0)"
            :ritten-vergelijk="Object.values(grafiekDataVergelijk).map(el => el.count || 0)"
            :waarden-bam="timeframe === 'MONTH' ? bams.reservaties.daily : bams.reservaties.monthly"
            :waarden-target="timeframe === 'MONTH' ? targets.reservaties.daily : targets.reservaties.monthly"
            ritten-label="Aantal enkele ritten"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'

import { useMarketingBamTargets, useMarketingGraph } from '@/pages/stats/Dashboard2/useMarketingStats'

import StatsCalculatedAt from '@/components/Stats/CalculatedAt.vue'
import UILoading from '@/components/UI/Loading.vue'

import useRenderKey from '@/hooks/useRenderKey'

const props = defineProps({
  endpoint: { type: String, required: true },
  yearly: Boolean,
  filters: Object,
  filtersVergelijken: Object,
  renderKey: [String, Number],
})

const BarStatsAPR = defineAsyncComponent(() => import('@/components/Charts/BarStatsAPR.vue'))

useRenderKey(props.renderKey)

const timeframe = computed(() => props.yearly ? 'YEAR' : 'MONTH')
const apiData = computed(() => ({ ...(props.filters || {}), timeframe: timeframe.value }))
const apiDataVergelijk = computed(() => (!props.filtersVergelijken ? null : { ...(props.filtersVergelijken || {}), timeframe: timeframe.value }))

const {
  bams,
  targets,
} = useMarketingBamTargets({
  year: apiData.value?.year,
  month: apiData.value?.month,
  avg_price: props.filters?.avg_price,
})

const {
  loading,
  calculatedAt,
  grafiek: grafiekData,
} = useMarketingGraph({
  apiData,
  endpoint: props.endpoint,
})

const {
  loading: loadingVergelijk,
  grafiek: grafiekDataVergelijk,
} = useMarketingGraph({
  apiData: apiDataVergelijk,
  endpoint: props.endpoint,
})
</script>
